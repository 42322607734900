


















import Vue from 'vue';
import { VueEditor } from 'vue2-editor';

interface Data {
  isFocused: boolean;
  editorText: String;
  customToolbar: object;
}
export default Vue.extend({
  name: 'MaterialMarkdownEditor',
  components: {
    VueEditor,
  },
  props: {
    id: { type: String, required: false },
    label: { type: String, required: true },
    value: String,
    note: { type: String, required: false },
    errors: { type: Array, required: false },
  },
  data(): Data {
    return {
      isFocused: true,
      editorText: this.value,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline'], 
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' },
        ],
        ['blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ color: [] }],
        ['image'],
        ['clean'],
      ],
    };
  },
  computed: {
    isInvalid(): boolean {
      return this.errors != null && this.errors.length > 0;
    },
  },
  methods: {
    isEmpty(): boolean {
      return this.$refs.toastuiEditor ? this.editorText == '' : true;
    },
    onInput() {
      this.$emit('input', this.editorText);
    },
    onBlur() {
      if (this.isEmpty()) {
        this.isFocused = false;
      }
      this.$emit('blur', this.editorText);
    },
  },
  mounted() {
    if (this.isEmpty()) {
      this.isFocused = false;
    }
  },
});
