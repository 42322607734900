


























import Vue from 'vue';
import api from '@/api';
import { UUID } from '@/models/Types';

interface breadcrumbItem {
  key: UUID;
  value: string;
}

interface Data {
  tabs: any;
  breadcrumbs: breadcrumbItem[];
}

export default Vue.extend({
  name: 'BreadCrumb',
  props: {
    cid: { type: String, required: true },
    activePage: { type: Object },
  },
  data(): Data {
    return {
      tabs: [
        { icon: 'house-fill', label: 'Dashboard', linkName: 'home' },
        { icon: 'patch-question-fill', label: 'FAQ', link: null, active: true },
      ],
      breadcrumbs: [],
    };
  },
  methods: {
    traverse(link: breadcrumbItem) {
      const url = window.location.href.split('#');

      if (
        url &&
        Array.isArray(url) &&
        url.length > 1 &&
        url[1].includes(`/boards/${link.key}`)
      ) {
        return;
      }
      this.$router.push({ path: `/boards/${link.key}` });
    },
    async fetchBreadCrumbData() {
      try {
        const rslt = await this.$http.get(
          `${api}/account/breadcrumbs/${this.cid}`
        );
        this.breadcrumbs = rslt.data;
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }
    },
  },
  mounted() {
    this.fetchBreadCrumbData();
  },
});
